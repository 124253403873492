




























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ConfirmChangesForm',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirmSubmit')
    },
  },
})
